<template>
  <div class="about-us">
    <contact-us-guestlist ref="contactModal" />

    <div id="Content">
      <div class="content-inner">
        <div class="row header">
          <div class="col flex center">
            <h1>Let's Make Your Event</h1>
          </div>
          <div class="col flex center">
            <h1 class="incredible">INCREDIBLE!</h1>
          </div>
        </div>

        <div class="row cta">
          <div class="col quarterx3 flex center">
            <iframe
              src="https://www.youtube.com/embed/krs4cPFYIKU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col quarterx3  flex center">
            <FormulateInput
              type="button"
              name="Schedule a Demo Today!"
              @click="demo"
            />
          </div>
        </div>

        <div class="row features-link">
          <TabTote
            :tabs="tabs"
            @click="onClickNavTab"
            :selected="navTabSelected"
          />
        </div>
      </div>
      <router-view />
      <div class="content-inner">
        <div class="row contact-us">
          <div class="col quarterx3  flex center">
            <FormulateInput
              type="button"
              name="Schedule a Demo Today!"
              @click="demo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutTile from "@/components/AboutTile.vue";
import ContactUsGuestlist from "../components/modals/ContactUsGuestlist.vue";
import TabTote from "@/components/TabTote.vue";

export default {
  name: "rates",
  components: {
    AboutTile,
    ContactUsGuestlist,
    TabTote
  },
  head() {
    return {
      title: "Sell Tickets Online • Rates • INCREDEVENT",
      meta: [
        {
          name: "description",
          content:
            "Event Management Platform. Sell Tickets. Discover Events. INCREDEVENT is an all-inclusive, industry-leading tool. Who are the creators? Find out here."
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion"
        },
        {
          name: "author",
          content: "INCREDEVENT"
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0"
        }
      ]
    };
  },

  data() {
    return {
      tabs: ["Ticketing", "Guest List"]
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("ticketing")) {
        return 0;
      } else if (path.endsWith("guest-list")) {
        return 1;
      }
    }
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;
      const url = this.$route.params.url;
      if (index === 0 && !path.endsWith("ticketing")) {
        this.$router.push(`ticketing`);
      } else if (index === 1 && !path.endsWith("guest-list")) {
        this.$router.push(`guest-list`);
      }
    },

    demo() {
      //this.$refs.contactModal.open();
      window.open("https://syncds-incredevent.zohobookings.com", "_blank");
    },

    scrollSection() {}
  },
  created() {
    this.$store.commit("setTitle", "Rates");
  }
};
</script>


<style lang="less" scoped>
.content-inner {
  padding-bottom: 0 !important;
  h1 {
    font-size: 24px;
    color: white;
  }

  h1,
  h2,
  h3,
  h4 {
    color: white;
  }

  .row.cta,
  .row.contact-us {
    iframe {
      border-radius: 10px;
      width: 100%;
      height: 400px;
      margin: 48px 0;
    }

    .formulate-input {
      ::v-deep button {
        width: 100%;
      }
    }
  }

  .row.contact-us {
    margin-top: 17px !important;
    margin-bottom: 32px !important;
  }

  .row.header {
    h1 {
      margin-bottom: 0;

      &.incredible {
        background: linear-gradient(
          270deg,
          rgba(68, 182, 120, 1) 0%,
          rgba(43, 172, 217, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        font-size: 32px;
      }
    }
  }

  .footer-text {
    p {
      font-size: 20px;
      text-align: center;
      span {
        font-weight: bold;
        background: linear-gradient(
          270deg,
          rgba(68, 182, 120, 1) 0%,
          rgba(43, 172, 217, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .row.features-link {
    margin: 32px 0 !important;

    h2 {
      margin: 8px 0;
      cursor: pointer;
    }
  }
  .row.features {
    ul {
      padding-inline-start: 24px;
    }
    .card {
      width: 100%;
      padding: 16px !important;
    }
  }

  .formulate-input.avg-fees {
    ::v-deep input:disabled {
      opacity: 0.8;
    }
    ::v-deep input {
      font-weight: bold;
      color: var(--primary-green);
    }
  }

  .formulate-input.ind-avg {
    ::v-deep input:disabled {
      opacity: 0.8;
    }
    ::v-deep input {
      font-weight: bold;
      color: red;
    }
  }

  .row.divider-text {
    margin: 16px;
  }

  h2 {
    color: #ffffff;
    text-align: center;
  }

  .individuals-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .tabs {
    flex-wrap: nowrap !important;
  }
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 22px;
    }
    .row.cta {
      iframe {
        height: 300px;
      }
    }
    .row {
      .col.quarter,
      .col.quarterx3 {
        width: 100% !important;
      }
    }
  }
}
</style>
